import React, { useState, useEffect } from "react"
import GoogleMapReact from "google-map-react"
import Marker from "./Marker"
import mapStyles from "../mapStyle.js"
import marks from "./marks.js"
import styled from "styled-components"
import Filterbutton from "./Filter"
// import { useWindowSize } from "../../../utils/useWindowSize"

import skolor from "../../../images/svg/skolor.svg"
import kommunikationer from "../../../images/svg/kommunikation.svg"
import lekplatser from "../../../images/svg/lekplatser.svg"
import matbutiker from "../../../images/svg/matbutik.svg"
import parker from "../../../images/svg/parker.svg"

const Map = () => {
  const [zoom, setZoom] = useState(16)
  const [mapCenter, setMapCenter] = useState({
    lat: 55.565647,
    lng: 12.952719,
  })

  const [size, setSize] = useState("test")

  useEffect(() => {
    
    const handleResize = () => {
      setSize(window.innerWidth)

      if (size < 1200) {
        setZoom(15)
        setMapCenter({ lat: 55.565934, lng: 12.943649 })
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)

  }, [size])

  // Map default props
  const defaultProps = {
    center: mapCenter,
    zoom: zoom,
    mapStyles: mapStyles,
    styles: mapStyles,
  }

  // All pin placements

  const [places, setplaces] = useState(marks)

  // Filter based on category
  const filterCategory = (places, string) => {
    // remove all checked classes
    document
      .querySelectorAll(".filterContainer__filterButton p")
      .forEach(p => p.classList.remove("checked"))
    // add class to clicked target
    window.event.target.classList.add("checked")
    let newPlaces = places.filter(place => {
      if (place.category === string || place.category === "alla") {
        return place
      } else {
        return null
      }
    })
    return setplaces(newPlaces)
  }

  // Render all the markers
  const renderMarkers = places =>
    Object.keys(places).map(key => {
      return (
        <Marker
          key={key}
          lat={places[key].lat}
          lng={places[key].lng}
          id={places[key].id}
          name={places[key].name}
          icon={places[key].icon}
          desc={places[key].desc}
          category={places[key].caregory}
        />
      )
    })

  // Filter places and return
  return (
    <MapStyle id="map">
      <GoogleMapReact
        // old
        // bootstrapURLKeys={{ key: "AIzaSyCBA604xkIr5Rr81BEH47TeSiKB-XVOVXE" }}
        // new
        bootstrapURLKeys={{ key: "AIzaSyAq7TBqwagV6oUAV8KQ_UjQJcxsWu7vi0w" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          fullscreenControl: false,
          styles: defaultProps.styles,
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          disableDefaultUI: true,
          minZoom: defaultProps.zoom - 1000,
          maxZoom: defaultProps.zoom + 1000,
        }}
      >
        <Marker
          lat={55.565995}
          lng={12.944205}
          id={"permanent"}
          filterCategory={`Kalcium`}
        />
        {renderMarkers(places)}
      </GoogleMapReact>
      <div className="filterContainer">
        <Filterbutton
          count={5}
          image={skolor}
          category="skolor"
          label="skolor"
          filterCategory={filterCategory}
        />
        <Filterbutton
          count={1}
          image={matbutiker}
          category="matbutiker"
          label="Matbutiker"
          filterCategory={filterCategory}
        />
        <Filterbutton
          count={5}
          image={parker}
          category="parker"
          label="Parker"
          filterCategory={filterCategory}
        />
        <Filterbutton
          count={3}
          image={lekplatser}
          category="lekplatser"
          label="Lekplatser"
          filterCategory={filterCategory}
        />
        <Filterbutton
          count={7}
          image={kommunikationer}
          category="kommunikationer"
          label="kommunikationer"
          filterCategory={filterCategory}
        />
      </div>
    </MapStyle>
  )
}

const MapStyle = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  filter: brightness(90%);

  .filterContainer {
    position: absolute;
    z-index: 9999;
    display: flex;
    bottom: 20px;
    left: 20px;

    @media only screen and (max-width: 1200px) {
      width: 60px;
      display: flex;
      flex-direction: column;
      left: 0;
      bottom: 2rem;
    }
  }
`

export default Map
