import skolor from '../../../images/svg/skolor.svg'
import kommunikationer from '../../../images/svg/kommunikation.svg'
import lekplatser from '../../../images/svg/lekplatser.svg'
import matbutiker from '../../../images/svg/matbutik.svg'
import parker from '../../../images/svg/parker.svg'

const marks = [
  {
    name: "Kalkbrottet",
    desc: "Busshållplats",
    category: "kommunikationer",
    lat: 55.57097512452646, 
    lng: 12.941823463792936,
    icon: kommunikationer,
  },
  {
    name: "Kalkbrottsplatsen",
    desc: "Busshållplats",
    category: "kommunikationer",
    lat: 55.56798417808559,
    lng: 12.945861758474322,
    icon: kommunikationer,
  },
  {
    name: "Elinelundsskolan",
    desc: "Busshållplats",
    category: "kommunikationer", 
    lat: 55.56422574380212,
    lng: 12.944391580776895,
    icon: kommunikationer,
  },
  {
    name: "Djupadalsstigen",
    desc: "Busshållplats",
    category: "kommunikationer",
    lat: 55.56957045528297,
    lng: 12.951713924888526,
    icon: kommunikationer,
  },
  {
    name: "Blåsebergavägen",
    desc: "Bilpool",
    category: "kommunikationer",
    lat: 55.569361,
    lng: 12.945289,
    icon: kommunikationer,
  },
  {
    name: "Tröskgatan",
    desc: "Bilpool",
    category: "kommunikationer",
    lat: 55.568600,
    lng: 12.947346,
    icon: kommunikationer,
  },
  {
    name: "Högatan 11",
    desc: "Bilpool",
    category: "kommunikationer",
    lat: 55.567997,
    lng: 12.949445,
    icon: kommunikationer,
  },
  {
    name: "Blåsebergastråket",
    desc: "Promenadstråk",
    category: "parker",
    lat: 55.567512, 
    lng: 12.948460,
    icon: parker,
  },
  {
    name: "Dungen",
    desc: "Lekplats",
    category: "lekplatser",
    lat: 55.562412,
    lng: 12.947422,
    icon: parker,
  },
  {
    name: "Kalkbrottet",
    desc: "Naturresservat",
    category: "parker",
    lat: 55.566715, 
    lng: 12.941564,
    icon: parker,
  },
  {
    name: "Kalkbrottstorget",
    desc: "Utkiksplats",
    category: "parker",
    lat: 55.568554,
    lng: 12.944378,
    icon: parker,
  },
  {
    name: "Parkkilen",
    desc: "Park",
    category: "parker",
    lat: 55.565039,
    lng: 12.949559,
    icon: parker,
  },
  {
    name: "Promenadstråket",
    category: "parker",
    lat: 55.563287,
    lng: 12.942071,
    icon: parker,
  },
  {
    name: "Elinelunds lekplats",
    desc: "Lekplats",
    category: "lekplatser",
    lat: 55.566230,
    lng: 12.948225,
    icon: lekplatser,
  },
  {
    name: "Mossängens lekplats",
    desc: "Lekplats",
    category: "lekplatser",
    lat: 55.563681,
    lng: 12.951206,
    icon: lekplatser,
  },
  {
    name: "Hemköp",
    desc: "Livsmedelbutik",
    category: "matbutiker",
    lat: 55.568449, 
    lng: 12.945925,
    icon: matbutiker,
  },
  {
    name: "Tröskans förskola",
    desc: "Skola",
    category: "skolor",
    lat: 55.569538497210225,
    lng: 12.950859504122027,
    icon: skolor,
  },
  {
    name: "Elinelundskolan",
    desc: "Skola",
    category: "skolor",
    lat: 55.564243,
    lng: 12.944901,
    icon: skolor,
  },
  {
    name: "Förskola Pilängen",
    desc: "Skola",
    category: "skolor",
    lat: 55.565850,
    lng: 12.957930,
    icon: skolor,
  },
  {
    name: "Hyllie Park Grundskola",
    desc: "Skola",
    category: "skolor",
    lat: 55.566594, 
    lng: 12.952252,
    icon: skolor,
  },
  {
    name: "Hyllie Park Förskola",
    desc: "Skola",
    category: "skolor",
    lat: 55.567452,
    lng: 12.953058,
    icon: skolor,
  },
]

export default marks