import React, { useState } from "react"
import styled from "styled-components"
import permanentMarker from "../markerIcon.svg"

const Marker = props => {
  const { name, id, category, key, desc, icon } = props
  const [showingInfoWindow, setshowingInfoWindow] = useState(false)

  const onMarkerInteraction = event => showingInfoWindow ? setshowingInfoWindow(false) : setshowingInfoWindow(true)

  const InfoWindow = props => {
    if (showingInfoWindow) {
      return (
        <div className="infobox">
          <p>
            {props.name}
            <span>{props.desc}</span>
          </p>
          {/* <span className="exit">X</span> */}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Mark
      role="button"
      tabIndex={0}
      onMouseEnter={onMarkerInteraction}
      onMouseLeave={onMarkerInteraction}
      // onClick={onMarkerInteraction}
      // onKeyDown={onMarkerInteraction}
    >
      <div key={key} id={id} title={name} className={`pin bounce ${category}`}>
        {icon ? <img src={icon} alt="marker icon" /> : null}
      </div>
      <InfoWindow name={name} role="exit" desc={desc} />
    </Mark>
  )
}

const Mark = styled.div`

@keyframes slidein {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(100%);
  }
  
  100% {
    transform: translateX(0%);
  }
}

.pin {
  z-index: 99999999;
  width: 30px;
  height: 30px;
  background: rgba(90,95,33,0.7);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  border: 1px solid #fff;
  transition: ease-in-out 0.3s;
  outline: none;  

  .bounce {
    animation: slidein 2.3s ease; 
  }

  img {
    width 80%;
    max-width: 15px;
    position: absolute;
    top:50%;
    right:50%;
    transform: translate(50%, -50%);
    margin-bottom: 0;
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.2);
    background:rgba(90,95,33,1);
    border-radius: 50%;
    border: 1px solid transparent;
  }
}

.filterContainer {
  position: absolute;
  z-index: 999;
  background: rgba(255, 255, 255, 0.7);
  padding: 30px;
  width: 80%;
  top: 5%;
  right: 10%;
  font-size: 28px;
  &__title {
    font-family: "century";
    font-size: 22px;
    margin-bottom: 4px;
  }
  &__filterButton {
    cursor: pointer;
    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 5px;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .checked .checkbox {
    background-color: #39506d;
  }
  .checkbox {
    width: 15px;
    height: 15px;
    display: block;
    background-color: #fff;
    margin-right: 10px;
    border: 1px solid #39506d;
    pointer-events: none;
    transition: ease 0.3s;
    &:hover {
      background-color: #39506d;
    }
  }
}

.infobox {
  display: flex;
  justify-content: center;
  width:100%;
  bottom: 0;
  left: -5px;
  transform: translate(-50%, calc(-100% - 28px));
  position: relative;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  z-index: 99999999999 !important;
  text-align: center;
  cursor: default;
  color: rgba(44,27,14,0.8);
  
  p {
    position: relative;
    padding: 1rem 1rem;
    background: #fff;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    text-transform: uppercase;
    white-space: nowrap;
    span {
      display: block;
      font-weight: 300;
    }
  }
      &:after {
        content:"";
        position:absolute;
        left:calc(50% - 5px);
        bottom:-5px;
        width:10px;
        height:10px;
        background-color: #fff;
        z-index: 9;
        transform: rotate(45deg);
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        border-right: 1px solid rgba(0, 0, 0, 0.10);
      }

  &:focus {
    border: 10px solid red;
  }

  h5 {
    margin-bottom: 0px;
    display: block;
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
}

span.exit {
  position: absolute;
  right: 5px;
  top: 5px;
  line-height: 12px;
}

div#permanent {
  background-color: unset;
  pointer-events: none;
  width: 61px;
  height: 61px;
  background-size: contain;
  border-radius: 0;
  background-image: url('${permanentMarker}');
  background-repeat: no-repeat;
  position: relative;
  top:-8px;
  left:2px;

  & + .infobox {
    display:none;
  }
  &::after {
    display:none;
  }
}

@media only screen and (min-width: 740px) {
  .filterContainer {
    max-width: 350px;
    top: 0;
    right: 0;
    &__title {
      font-size: 25px;
    }
  }
}
`

export default Marker
