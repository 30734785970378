import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import FilterFacts from "./FilterFacts"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { CSSTransition } from "react-transition-group"

const Facts = () => {
  const data = useStaticQuery(graphql`
    query AboutArea {
      kalkbrottet: mdx(frontmatter: { title: { eq: "Kalkbrottet" } }) {
        frontmatter {
          title
        }
        body
      }

      kalkThumbnail: file(relativePath: { eq: "kalkbrottet/kalkbrottet.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kalkImage: file(relativePath: { eq: "kalkbrottet/kalkbrottet_big.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      berguv: mdx(frontmatter: { title: { eq: "Berguv" } }) {
        frontmatter {
          title
        }
        body
      }

      berguvThumbnail: file(relativePath: { eq: "kalkbrottet/berguv.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      berguvImage: file(relativePath: { eq: "kalkbrottet/berguv_big.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      padda: mdx(frontmatter: { title: { eq: "Grönfläckig padda" } }) {
        frontmatter {
          title
        }
        body
      }

      paddaThumbnail: file(relativePath: { eq: "kalkbrottet/padda.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      paddaImage: file(relativePath: { eq: "kalkbrottet/padda_big.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      falk: mdx(frontmatter: { title: { eq: "Pilgrimsfalk" } }) {
        frontmatter {
          title
        }
        body
      }

      falkThumbnail: file(relativePath: { eq: "kalkbrottet/falk.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      falkImage: file(relativePath: { eq: "kalkbrottet/falk_big.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [allInfo] = useState({
    kalkbrottet: {
      thumbnail: data.kalkThumbnail,
      text: data.kalkbrottet,
      image: data.kalkImage,
    },
    berguv: {
      thumbnail: data.berguvThumbnail,
      text: data.berguv,
      image: data.berguvImage,
    },
    padda: {
      thumbnail: data.paddaThumbnail,
      text: data.padda,
      image: data.paddaImage,
    },
    falk: {
      thumbnail: data.falkThumbnail,
      text: data.falk,
      image: data.falkImage,
    },
  })

  const [currentInfo, setCurrentInfo] = useState(allInfo.kalkbrottet)
  const [previousInfo, setPrevious] = useState(allInfo.kalkbrottet)
  const [animate, setAnimate] = useState(false)

  const clickMeNow = obj => {
    setAnimate(true)
    setPrevious(currentInfo)

    setTimeout(() => {
      setCurrentInfo(obj)
      setAnimate(false)
    }, 300)
  }

  return (
    <Container>
      <Text>
        <CSSTransition in={animate} timeout={500} classNames="text-animate">
          <div>
            <MDXRenderer>{currentInfo.text.body}</MDXRenderer>
          </div>
        </CSSTransition>
      </Text>
      <FilterContainer>
        {Object.keys(allInfo).map(key => (
          <FilterFacts
            key={key}
            clickMeNow={clickMeNow}
            keyDown={clickMeNow}
            fact={allInfo[key]}
          />
        ))}
      </FilterContainer>
      <CSSTransition in={animate} timeout={500} classNames="my-node">
        <Img
          className="backgroundImage"
          fluid={currentInfo.image.childImageSharp.fluid}
        />
      </CSSTransition>
      <Img
        className="staticImage"
        fluid={previousInfo.image.childImageSharp.fluid}
      />
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  position: relative;
  height: 70vh;
  min-height: 900px;

  .backgroundImage {
    filter: brightness(70%);
    opacity: 1;
    z-index: -1;
  }

  .staticImage {
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: brightness(70%);
  }

  .backgroundImage,
  
  .staticImage {
    position: fixed !important;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .my-node {
    opacity: 0;
  }
  .my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  .my-node-exit {
    opacity: 0;
  }

  .my-node-exit-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  @media only screen and (min-width: 1800px) {
    height: 50vw;
  }

  @media only screen and (min-width: 1250px) {
    height: 900px;
  }

  @media only screen and (max-width: 800px) {
    height: auto;
    min-height: auto;
    
    .backgroundImage {
      position: absolute !important;
      top:0;
      left:0;
      height: 400px;
      max-height: 400px;
    }
    
    .staticImage {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 400px;
      max-height: 400px;
    }
    display: flex;
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 500px) {
    .backgroundImage, .staticImage {
      max-height: 300px;
      height: 300px;
    }

    .staticImage {
      display: none;
    }
  }
`

const Text = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  width: 420px;
  height: 100%;
  background-color: rgba(44, 27, 14, 0.7);
  padding: 2rem;
  padding-top: 4rem;
  color: #fff;

  .text-animate-enter {
    opacity: 1;
  }
  .text-animate-enter-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  .text-animate-exit {
    opacity: 0;
  }

  .text-animate-exit-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  p{
    font-size: 16px;
  }

  @media only screen and (max-width: 1250px) {
    width: 100%;
    bottom: 0;
    height: auto;
    border-top: 1px solid #fff;

    p {
      max-width: 800px;

      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    h2 {
      font-size: 60px;
      line-height: 55px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    position: unset;
    border-top: 0px solid #fff;
    padding-top: 15px;
  }

  @media only screen and (max-width: 500px) {
    padding: 1rem;
    h2 {
      white-space: pre-wrap;
    }
  }
`

const FilterContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;

  @media only screen and (max-width: 1250px) {
    top: 20px;
    bottom: unset;
    left: 20px;
  }

  @media only screen and (max-width: 800px) {
    margin-top: 400px;
    position: unset;
    background: rgba(44, 27, 14, 0.7);
    padding-left: 2rem;
    padding-top: 10px;
    padding-right: 2rem;
  }

  @media only screen and (max-width: 500px) {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 300px;
  }
`

export default Facts
