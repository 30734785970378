import React from "react"
import Map from "./Map"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

const MapBlock = props => {
  return (
    <Container>
      <Map />
      <Info>
        <MDXRenderer>{props.text.body}</MDXRenderer>
        <Img className="hideMobile" fluid={props.image}></Img>
      </Info>
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-template-rows: 100%;

  #map {
    height: 100%;
    grid-column: 1 / 3;
    grid-row: 1;
  }

  @media only screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr repeat(3,200px);

    #map {
      grid-row: 2 / 5;
    }
  }
`

const Info = styled.div`
  grid-column: 2 / 3;
  grid-row: 1;
  z-index: 99;
  background: rgba(44, 27, 14, 0.7);
  color: #fff;
  padding: 2rem;
  .gatsby-image-wrapper {
    height: auto;
  }
  p{
    font-size: 16px;
  }
  h3{
    font-family: HelveticaNeueRoman;
    font-size: 20px;
    font-weight: 500;
    margin-bottom:0;
  }

  @media only screen and (max-width: 1250px) {
    grid-column: 1;
    grid-row: 1;
    height: auto;
    border-top:1px solid #fff;

    .hideMobile {
      display: none;
    }
  }
  
  @media only screen and (max-width: 500px) {
    padding:2rem 1rem;
  }
`

export default MapBlock
