import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import FormSections from "../components/reusableUi/FormSections"
import MapBlock from "../components/maps/omradet/MapBlock"
import Facts from "../components/facts/Facts"
import { graphql } from "gatsby"
import icon from "../images/svg/ca-icon.svg"

const Omradet = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Området"
        description="Kalcium är inte bara ett livsnödvändigt grundämne. Det är också ett nytt välplanerat och trivsamt bostadsrättsprojekt med 61 lägenheter och tre radhus på bästa läge precis intill det majestätiska naturreservatet Kalkbrottet i den nya stadsdelen Elinegård."
      />
      <Hero
        iconImage={icon}
        heroClass="default-hero"
        heroImage={data.heroBackground.childImageSharp.fluid}
      />
      <MapBlock
        image={data.elinegardImage.childImageSharp.fluid}
        text={data.elinegard}
      />
      <Facts />
      <FormSections />
    </Layout>
  )
}

export default Omradet

export const pageQuery = graphql`
  query {
    elinegard: mdx(frontmatter: { title: { eq: "Elinegård" } }) {
      frontmatter {
        title
      }
      body
    }

    elinegardImage: file(relativePath: { eq: "oversikt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBackground: file(relativePath: { eq: "Kalkbrottet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
