import React from "react"
import marks from "./marks"
import styled from "styled-components"

const Filter = props => {
  return (
    <FilterButton
      tabIndex={0}
      role="button"
      // onKeyDown={() => {props.filterCategory(marks, props.category)}}
      onClick={() => {props.filterCategory(marks, props.category)}}
    >
      <div>
        <span className="counter">{props.count}</span>
        <img src={props.image} alt={props.label} />
        <p>
          <span className="checkbox"></span>
          {props.label}
        </p>
      </div>
    </FilterButton>
  )
}

const FilterButton = styled.button`
    position: relative;
    width: 120px;
    height: 120px;
    background-color: rgba(90, 95, 33, 0.7);
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    margin: 5px;
    outline-color: rgba(90, 95, 33, 0.7);
    cursor: pointer;

    &:focus {
      outline: none;
    }

    .counter {
      position: absolute;
      top:4px;
      left:8px;
      font-size: 20px;
    }
 
    img {
      max-width: 40px;
      margin-bottom:0;
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
    }

    p {
      position: absolute;
      margin-bottom:0;
      bottom:4px;
      left:50%;
      transform: translateX(-50%);
      font-size: 10px;
    }

    @media only screen and (max-width: 1200px) {
      width: 50px;
      height: 50px;
      
      img {
        max-width: 25px;
      }
      p {
        display: none;
      }

      .counter {
        font-size: 14px;
        display: none;
      }
    }
`

export default Filter
