import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const FilterFacts = ( props ) => {
const { clickMeNow, fact } = props

  return (
    <Container onClick={ () => {clickMeNow(fact)}}>
      <Img fluid={fact.thumbnail.childImageSharp.fluid} />
    </Container>
  )
}
const Container = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
  cursor: pointer;
  margin: 5px;
  border: 1px solid #fff;

  @media only screen and (max-width: 800px) {
    height: 80px;
    width: 80px;
  }
  
  @media only screen and (max-width: 500px) {
    height: auto;
  }
`
export default FilterFacts
 